import { useCallback, useEffect, useMemo, useState } from 'react'

import {
  ConfigurableProperty,
  DeviationSettingLevel,
  DigitalTwinSetting,
  useDigitalTwin,
  useDigitalTwinSettings,
} from 'api/digitalTwin/digitalTwin.api'
import { DEVIATION_SETTING_PRIORITIES } from 'api/digitalTwin/digitalTwinConstants'
import { colors } from 'styles/variables'
import { useAuth } from 'ui/components/AuthContext/AuthContext'
import { getPropertiesFromDigitalTwinModel } from 'utils/digitalTwinSettings/digitalTwinSettingUtils'

import i18n from 'i18next'

import { useHasPermission } from 'helpers/global.helper/global.helper'

export type EventStatus = 'Draft' | 'Sent' | 'Active' | 'Inactive'

export type EventPost = {
  digital_twin_id: number | undefined
  system_id: number | undefined
  id?: number
  status: EventStatus
  start_time: ISODateTime
  end_time: ISODateTime
  name?: string
  input_field?: {
    ProductionEvent?: string
    GridEvent?: string
  }
  operational_event_type?: 'ProductionEvent' | 'GridEvent'
  schedulability?: 'MoveableWithinLimits' | 'AlwaysMoveable' | 'NotMoveable'
  schedulability_effect?: string
  earliest_start_time?: ISODateTime
  latest_end_time?: ISODateTime
  category_id?: number
  created_at?: ISODateTime
  updated_at?: ISODateTime
  deleted_at?: ISODateTime
  created_by?: string
  updated_by?: string
  linked_objects?: EventProperties | []
  tags?: string[]
  require_umm: boolean
  cancelable_after_start?: boolean
}

export type EventPostListItem = Omit<EventPost, 'input_field'>

export type EventCategory = {
  id: number | null
  display_name: string | null
}

export type EventProperties = {
  object_category: DeviationSettingLevel | string
  object_name: string
  attribute: string
}[]

export function getLabelForEventStatus(status: EventStatus): string {
  let statusLabel: string
  if (status === 'Draft') {
    statusLabel = i18n.t('Draft')
  } else if (status === 'Sent') {
    statusLabel = i18n.t('Submitted')
  } else if (status === 'Active') {
    statusLabel = i18n.t('Active')
  } else if (status === 'Inactive') {
    statusLabel = i18n.t('Inactive')
  } else {
    statusLabel = status
  }
  return statusLabel
}

export function getColorForEventStatus(status: EventStatus): string {
  let color: string
  if (status === 'Draft') {
    color = '#cda294'
  } else if (status === 'Sent') {
    color = '#6baedf'
  } else if (status === 'Active') {
    color = colors.success3
  } else if (status === 'Inactive') {
    color = colors.grey5
  } else {
    color = colors.primary3
  }
  return color
}

export const EVENT_TYPES = [
  { value: 'ProductionEvent', label: 'Production event' },
  { value: 'GridEvent', label: 'Grid event' },
]

export const SCHEDULABILITIES = [
  { value: 'MoveableWithinLimits', label: 'Movable within limits' },
  { value: 'AlwaysMoveable', label: 'Always movable' },
  { value: 'NotMoveable', label: 'Not movable' },
]

export function useOperationalEventsPermissions(): Record<string, boolean> {
  const hasUpdateOperationalEventsAccess = useHasPermission('update_operational_events')
  const hasDeleteOperationalEventsAccess = useHasPermission('delete_operational_events')
  const hasUpdateActiveOperationalEventsAccess = useHasPermission('update_active_operational_events')
  const hasDeleteActivatedOperationalEventsAccess = useHasPermission('delete_activated_operational_events')
  const hasDefaultStatusHandlingOperationalEventsAccess = useHasPermission('default_status_handling_operational_events')
  const hasActiveStatusHandlingOperationalEventsAccess = useHasPermission('active_status_handling_operational_events')
  const hasViewOperationalEventsAccess = useHasPermission('view_operational_events')
  const hasCreateOperationalEventsAccess = useHasPermission('create_operational_events')

  return useMemo(
    () => ({
      hasUpdateOperationalEventsAccess,
      hasDeleteOperationalEventsAccess,
      hasUpdateActiveOperationalEventsAccess,
      hasDeleteActivatedOperationalEventsAccess,
      hasDefaultStatusHandlingOperationalEventsAccess,
      hasActiveStatusHandlingOperationalEventsAccess,
      hasViewOperationalEventsAccess,
      hasCreateOperationalEventsAccess,
    }),
    [
      hasUpdateOperationalEventsAccess,
      hasDeleteOperationalEventsAccess,
      hasUpdateActiveOperationalEventsAccess,
      hasDeleteActivatedOperationalEventsAccess,
      hasDefaultStatusHandlingOperationalEventsAccess,
      hasActiveStatusHandlingOperationalEventsAccess,
      hasViewOperationalEventsAccess,
      hasCreateOperationalEventsAccess,
    ]
  )
}

type UseFetchOperationalEventSettingsReturn = {
  settings: DigitalTwinSetting[]
  refetchSettings: () => void
}

export function useFetchOperationalEventSettings(eventPostId?: number): UseFetchOperationalEventSettingsReturn {
  const { activeSystem } = useAuth()
  const primaryDigitalTwin = activeSystem?.primary_digital_twin
  const { data: digitalTwin } = useDigitalTwin(primaryDigitalTwin?.uid, true)
  const { mutateAsync: fetchSettings } = useDigitalTwinSettings()

  const [settings, setSettings] = useState<DigitalTwinSetting[]>([])

  const priorities = useMemo(
    () => [...DEVIATION_SETTING_PRIORITIES, ...(activeSystem?.setting_priority_levels ?? [])],
    [activeSystem]
  )

  const allConfigurableProperties: ConfigurableProperty[] = useMemo(
    () => (digitalTwin?.model ? getPropertiesFromDigitalTwinModel(digitalTwin.name, digitalTwin.model) : []),
    [digitalTwin]
  )

  const refetchSettings = useCallback(() => {
    if (allConfigurableProperties.length === 0) {
      return
    }
    fetchSettings({
      digitalTwinUid: digitalTwin?.uid,
      properties: allConfigurableProperties,
      priorities,
      operational_event: eventPostId,
      operational_event_include_inactive: true,
    }).then((data) => {
      setSettings(data)
    })
  }, [digitalTwin?.uid, eventPostId, fetchSettings, allConfigurableProperties, priorities])

  useEffect(() => {
    if (eventPostId) {
      refetchSettings()
    }
  }, [refetchSettings, eventPostId])

  return { settings, refetchSettings }
}

export function compareEvents(a: EventPostListItem, b: EventPostListItem): number {
  if (a.status === 'Active' && b.status !== 'Active') {
    return 1
  }

  if (a.status !== 'Active' && b.status === 'Active') {
    return -1
  }

  if (a.id === undefined || b.id === undefined) {
    return 0
  }

  return a.id - b.id
}
export function getEventFields(operationalEventType: string): string {
  if (operationalEventType === 'ProductionEvent') {
    return `<h3>${i18n.t('Description of activity')}:</h3><h3>${i18n.t('Dependencies and limitations')}:</h3><h3>${i18n.t('Estimated MPK')}:</h3>`
  } else if (operationalEventType === 'GridEvent') {
    return `<h3>${i18n.t('Affected Littran')}:</h3><h3>${i18n.t('Weather and availability')}:</h3><h3>${i18n.t('Impact on grid operation')}:</h3><h3>${i18n.t('Impact on control room operation')}:</h3><h3>${i18n.t('Affected meters')}:</h3><h3>${i18n.t('Impact on water quantities')}:</h3>`
  }
  return ''
}

export const MAX_SCHEDULABILITY_EFFECT_INPUT_LENGTH = 200
