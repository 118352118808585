import React, { ReactElement, useMemo, useState, useEffect, useCallback, useRef } from 'react'

import { useEventDeleteMutation, useEventUpdateMutation, useGetEventCategories , useGetEvent} from 'api/events/events.api'
import alertStore from 'store/alert/alert'
import { colors } from 'styles/variables'
import { Button } from 'ui/atoms'
import Icon from 'ui/atoms/Icon/Icon'
import { useAuth } from 'ui/components/AuthContext/AuthContext'
import ConfirmDialog from 'ui/components/ConfirmDialog/ConfirmDialog'
import { Dialog } from 'ui/components/Dialog/Dialog'
import Dropdown from 'ui/components/Dropdown/Dropdown'
import InfoBanner from 'ui/components/InfoBanner/InfoBanner'
import CustomDialogTitle from 'ui/components/SettingsModal/components/CustomDialogTitle/CustomDialogTitle'
import Tooltip from 'ui/components/Tooltip/Tooltip'
import { DatePickerPeriod } from 'ui/molecules/pickers/DatePicker/DatePickerPeriod'
import Datetime from 'utils/datetime/datetime'

import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  DialogContent,
  Divider,
  TextField,
  Checkbox as MuiCheckbox,
  FormGroup,
  FormControlLabel,
  Icon as MuiIcon,
} from '@mui/material'
import { useTranslation } from 'react-i18next'
import ReactQuill from 'react-quill-new'

import EventPropertiesDropdowns from '../EventPropertiesDropdowns/EventPropertiesDropdowns'
import EventSettingsOverviewTable from '../EventSettingsOverviewTable/EventSettingsOverviewTable'
import EventStatus from '../EventStatus/EventStatus'
import { EventPost, EventStatus as EventStatusType, EVENT_TYPES, SCHEDULABILITIES, useOperationalEventsPermissions, useFetchOperationalEventSettings, MAX_SCHEDULABILITY_EFFECT_INPUT_LENGTH, getEventFields } from '../events.helper'

import styles from './EventPostModal.module.less'

type EventPostModalProps = {
  closeModal: () => void
  newEvent?: boolean
  selectedEventPostId?: number | null
}

const reactQuillModules = {
  toolbar: [
    [{ header: [1, 2, 3, 4, 5, 6, false] }],
    ['bold', 'italic', 'underline', 'strike', 'blockquote'],
    [{ align: ['right', 'center', 'justify'] }],
    [{ list: 'ordered' }, { list: 'bullet' }],
    ['link', 'image'],
  ],
}

const MAX_IMAGE_SIZE_MB = 2.5

export default function EventPostModal({ closeModal, newEvent, selectedEventPostId }: EventPostModalProps): ReactElement {
  const { t } = useTranslation()
  const { activeSystem, systemId, hasAccess } = useAuth()

  const initialEventModalState = useMemo((): EventPost => {
    return {
      digital_twin_id: activeSystem?.primary_digital_twin?.id,
      system_id: systemId,
      start_time: Datetime.getISONow(),
      end_time: Datetime.getEndOfDay(Datetime.getISONow()),
      require_umm: false,
      status: 'Draft',
      input_field: {
        ProductionEvent: getEventFields('ProductionEvent'),
        GridEvent: getEventFields('GridEvent'),
      },
    }
  }, [activeSystem?.primary_digital_twin?.id, systemId])

  const [eventPost, setEventPost] = useState(initialEventModalState)
  const [confirmDeleteModalOpen, setConfirmDeleteModalOpen] = useState<boolean>(false)
  const [confirmOnCloseModalOpen, setConfirmOnCloseModalOpen] = useState<boolean>(false)
  const [confirmOnThrowAwayModalOpen, setConfirmOnThrowAwayModalOpen] = useState<boolean>(false)
  const [lastSavedValues, setLastSavedValues] = useState<EventPost>(initialEventModalState)
  const [isEditing, setIsEditing] = useState<boolean>(false)
  const [status, setStatus] = useState<EventStatusType>('Draft')
  const [openSettingsOverview, setOpenSettingsOverview] = useState<{ [index: number]: boolean }>({})
  const [unsavedEvent, setUnsavedEvent] = useState<boolean>(false)
  const [schedulabilityEffectLength, setSchedulabilityEffectLength] = useState<number>(eventPost?.schedulability_effect?.length || 0)
  const [editorErrorMessage, setEditorErrorMessage] = useState<string>('')

  const { settings, refetchSettings } = useFetchOperationalEventSettings(eventPost.id)
  const { data: selectedEventPost, isFetching } = useGetEvent(selectedEventPostId)
  const { mutateAsync: updateEventPost } = useEventUpdateMutation(systemId)
  const { mutateAsync: deleteEventPost } = useEventDeleteMutation()
  const eventCategories = useGetEventCategories(systemId).data || []
  const quillRef = useRef<ReactQuill | null>(null)

  const hasSettingsOutsideTimePeriod = useMemo(() => {
    if (!settings) {
      return false
    }

    return settings?.some(setting => {
      if (!setting.start_time || !setting.end_time) {
        return false
      }

      const startsBeforeEvent = Datetime.isBefore(Datetime.toISOString(setting.start_time), Datetime.toISOString(eventPost.start_time))
      const endsAfterEvent = Datetime.isAfter(Datetime.toISOString(setting.end_time), Datetime.toISOString(eventPost.end_time))
      return startsBeforeEvent || endsAfterEvent
    })
  }, [eventPost.end_time, eventPost.start_time, settings])

  const statusDraft = status === 'Draft'
  const statusSubmitted = status === 'Sent'
  const statusActive = status === 'Active'
  const statusInactive = status === 'Inactive'

  const {
    hasUpdateOperationalEventsAccess,
    hasDeleteOperationalEventsAccess,
    hasUpdateActiveOperationalEventsAccess,
    hasDeleteActivatedOperationalEventsAccess,
    hasDefaultStatusHandlingOperationalEventsAccess,
    hasActiveStatusHandlingOperationalEventsAccess,
  } = useOperationalEventsPermissions()

  const saveSensitiveData = useMemo(() => {
    const isEqual = (a: any, b: any): boolean => a === b || (a == null && b == null)

    const fieldsToCompare = [
      'operational_event_type',
      'category_id',
      'start_time',
      'end_time',
      'earliest_start_time',
      'latest_end_time',
      'schedulability',
      'require_umm',
    ]

    return fieldsToCompare.some(field => !isEqual(eventPost[field as keyof EventPost], lastSavedValues[field as keyof EventPost])) ||
      JSON.stringify(eventPost.linked_objects) !== JSON.stringify(lastSavedValues.linked_objects)
  }, [eventPost, lastSavedValues])

  const checkIfEditing = useCallback((newEventPost: EventPost) => {
    const propertiesToCheck = [
      'name', 'operational_event_type', 'category_id', 'start_time', 'end_time',
      'earliest_start_time', 'latest_end_time', 'schedulability', 'schedulability_effect',
      'require_umm', 'input_field', 'linked_objects', 'cancelable_after_start',
    ] as const

    const isEqual = (a: any, b: any): boolean => a === b || (a == null && b == null)

    return propertiesToCheck.some(prop => {
      return !isEqual(newEventPost[prop], lastSavedValues[prop])
    })
  }, [lastSavedValues])


  useEffect(() => {
    if (newEvent) {
      setUnsavedEvent(true)
    }
  }, [newEvent])

  useEffect(() => {
    if (selectedEventPost) {
      setEventPost(selectedEventPost)
      setLastSavedValues(selectedEventPost)
      setStatus(selectedEventPost.status)
    } else {
      setEventPost(initialEventModalState)
    }
  }, [selectedEventPost, initialEventModalState])

  useEffect(() => {
    // Reset the time values if the schedulability is changed
    if (eventPost.schedulability === 'NotMoveable') {
      setEventPost((prevEventPost) => ({
        ...prevEventPost,
        earliest_start_time: undefined,
        latest_end_time: undefined,
        schedulability_effect: undefined,
      }))
    } else if (eventPost.schedulability === 'AlwaysMoveable') {
      setEventPost((prevEventPost) => ({
        ...prevEventPost,
        earliest_start_time: undefined,
        latest_end_time: undefined,
      }))
    }
  }, [newEvent, eventPost.schedulability])

  useEffect(() => {
    if (eventPost?.schedulability === 'MoveableWithinLimits') {
      setEventPost((prevEventPost) => ({
        ...prevEventPost,
        earliest_start_time: Datetime.toISOString(Datetime.subtractHours(eventPost.start_time, 1)),
        latest_end_time: Datetime.toISOString(Datetime.addHours(eventPost.end_time, 1)),
      }))
    }
  }, [eventPost.schedulability, eventPost.start_time, eventPost.end_time])

  const resetEventPost = useCallback(() => {
    setEventPost(initialEventModalState)
    setLastSavedValues(initialEventModalState)
    setStatus(initialEventModalState.status)
    setIsEditing(false)
    setUnsavedEvent(false)
  }, [initialEventModalState])

  const isEventInitialState = useCallback((eventPost: EventPost): boolean => {
    const removeId = (obj: EventPost): Omit<EventPost, 'id'> => {
      const newObj = { ...obj }
      delete newObj.id
      return newObj
    }
    return JSON.stringify(removeId(eventPost)) === JSON.stringify(removeId(initialEventModalState))
  }, [initialEventModalState])

  const sendEvent = useCallback(async (data: EventPost) => {
    try {
      const updatedData = {
        ...data,
        status: 'Sent' as EventStatusType,
      }

      const response = await updateEventPost(updatedData)
      setEventPost(response)
      setLastSavedValues(response)
      setIsEditing(false)
      setUnsavedEvent(false)
      setStatus('Sent')
      alertStore.success(t(`Event submitted`))
    } catch (e) {
      alertStore.error(t(`Failed to submit event`))
    }
  }, [updateEventPost, t])

  const draftEvent = useCallback(async (data: EventPost) => {
    try {
      const updatedData = {
        ...data,
        status: 'Draft' as EventStatusType,
      }

      const response = await updateEventPost(updatedData)
      setEventPost(response)
      setLastSavedValues(response)
      setUnsavedEvent(false)
      setIsEditing(false)
      setStatus('Draft')
      alertStore.success(t(`Event saved as draft`))
    } catch (e) {
      alertStore.error(t(`Failed save event as draft`))
    }
  }, [updateEventPost, t])

  const updateEvent = useCallback(async (data: EventPost) => {
    try {
      const response = await updateEventPost(data)
      setLastSavedValues(response)
      setEventPost(response)
      setStatus(response.status)
    } catch (e) {
      alertStore.error(t(`Failed to update event`))
    }
  }, [t, updateEventPost])

  const activateEvent = useCallback(async (data: EventPost) => {
    try {
      const updatedData = {
        ...data,
        status: 'Active' as EventStatusType,
      }

      await updateEventPost(updatedData)
      setLastSavedValues(updatedData)
      setEventPost(updatedData)
      setStatus('Active')
      alertStore.success(t(`Event activated`))
    } catch (e) {
      alertStore.error(t(`Failed to activate event`))
    }
  }, [t, updateEventPost])

  const inactivateEvent = useCallback(async (data: EventPost) => {
    try {
      const updatedData = {
        ...data,
        status: 'Inactive' as EventStatusType,
      }

      await updateEventPost(updatedData)
      setLastSavedValues(updatedData)
      setEventPost(updatedData)
      alertStore.success(t(`Event inactivated`))
      setStatus('Inactive')
    } catch (e) {
      alertStore.error(t(`Failed to inactivate event`))
    }
  }, [t, updateEventPost])


  const deleteEvent = useCallback(async (id: number) => {
    try {
      await deleteEventPost(id)
      resetEventPost()
      if (!isEventInitialState(eventPost)) {
        alertStore.success(t(`Event deleted`))
      }
    } catch (e) {
      alertStore.error(t(`Failed to delete event`))
    }
  }, [deleteEventPost, eventPost, isEventInitialState, resetEventPost, t])

  const handleOnClose = useCallback((): void => {
    if (unsavedEvent) {
      setConfirmOnThrowAwayModalOpen(true)

      if (isEventInitialState(eventPost)) {
        deleteEvent(eventPost.id as number)
        closeModal()
      }
    } else if (isEditing && !unsavedEvent) {
      setConfirmOnCloseModalOpen(true)
    } else {
      resetEventPost()
      closeModal()
    }
  }, [unsavedEvent, isEditing, isEventInitialState, eventPost, deleteEvent, closeModal, resetEventPost])

  const handleOnSaveEdit = useCallback((): void => {
    setLastSavedValues(eventPost)

    const updatedEventPost = {
      ...eventPost,
      status: eventPost.status,
    }
    setEventPost(updatedEventPost)
    updateEvent(updatedEventPost)
    setIsEditing(false)
  }, [eventPost, setLastSavedValues, setEventPost, updateEvent, setIsEditing])

  const addEventPropertyField = useCallback((): void => {
    const updatedEventProperties = [...(eventPost.linked_objects || []), { object_category: '', object_name: '', attribute: '' }]
    setEventPost((prevEventPost) => ({
      ...prevEventPost,
      linked_objects: updatedEventProperties,
    }))
  }, [eventPost, setEventPost])

  const removeEventPropertyField = useCallback((index: number) => {
    const updatedEventProperies = (eventPost.linked_objects || []).filter((_, i) => i !== index)
    const newEventPost = {
      ...eventPost,
      linked_objects: updatedEventProperies,
    }
    setEventPost(newEventPost)
    setIsEditing(checkIfEditing(newEventPost))
  }, [checkIfEditing, eventPost])

  const toggleSettingOverview = useCallback((index: number) => {
    setOpenSettingsOverview((prevState) => ({
      ...prevState,
      [index]: prevState[index] === undefined ? true : !prevState[index] ,
    }))
  }, [])

  const handleEditorChange = useCallback((content: string, editor: any) => {
    
    const images = editor.getContents().ops.filter((op: any) => op.insert && op.insert.image)
    const oversizedImage = images.find((image: any) => {
      const url = image.insert.image
      const base64Data = url.includes('base64,') ? url.split('base64,')[1] : url // Remove base64 prefix if it exists
      const base64Size = base64Data.length * (3 / 4) - (base64Data.endsWith('==') ? 2 : base64Data.endsWith('=') ? 1 : 0)

      return base64Size > MAX_IMAGE_SIZE_MB * 1024 * 1024
    })
  
    if (oversizedImage) {
      setEditorErrorMessage(`The image size exceeds the maximum limit of ${MAX_IMAGE_SIZE_MB}MB.`)
    } else {
      setEditorErrorMessage('')
    }

    const currentEventType = eventPost.operational_event_type
    if (!currentEventType) return

    const newEventPost = {
      ...eventPost,
      input_field: {
        ...eventPost.input_field,
        [currentEventType as string]: content,
      },
    }
    setEventPost(newEventPost)
    setIsEditing(checkIfEditing(newEventPost))
  }, [checkIfEditing, eventPost])

  const inputFieldEditorContent = useMemo(() => {
    const currentEventType = eventPost.operational_event_type
    if (!currentEventType) return ''

    if (eventPost.input_field === undefined) {
      return getEventFields(currentEventType)
    }
    return eventPost.input_field[currentEventType]
  }, [eventPost.operational_event_type, eventPost.input_field])

  const disabledSameLinkedObjects = useMemo(() => eventPost.linked_objects?.some((property, index) => {
    return (eventPost.linked_objects ?? []).some((otherProperty, otherIndex) => {
      return index !== otherIndex && JSON.stringify(property) === JSON.stringify(otherProperty)
    })
  }), [eventPost.linked_objects])

  const disabledSubmit = useMemo(() => {
    return (
      eventPost.name === undefined || eventPost.name === ''
      || eventPost.operational_event_type === null || eventPost.operational_event_type === undefined
      || eventPost.category_id === null || eventPost.category_id === undefined
      || eventPost.schedulability === null || eventPost.schedulability === undefined
      || (eventPost.earliest_start_time && Datetime.isBefore(Datetime.toISOString(eventPost.start_time), Datetime.toISOString(eventPost.earliest_start_time)))
      || (Datetime.isBefore(Datetime.toISOString(eventPost.start_time), Datetime.toISOString('2000-01-01T00:00:00+00:00')))
      || (eventPost.latest_end_time && Datetime.isAfter(Datetime.toISOString(eventPost.end_time), Datetime.toISOString(eventPost.latest_end_time)))
      || (Datetime.isAfter(Datetime.toISOString(eventPost.end_time), Datetime.toISOString('2100-01-01T00:00:00+00:00')))
      || (Datetime.isAfter(Datetime.toISOString(eventPost.start_time), Datetime.toISOString(eventPost.end_time)))
      || eventPost.linked_objects?.some((property) => property.object_category === '' || property.object_name === '')
      || disabledSameLinkedObjects
      || !!editorErrorMessage
    )
  }, [disabledSameLinkedObjects, eventPost.category_id, eventPost.earliest_start_time, eventPost.end_time, eventPost.latest_end_time, eventPost.linked_objects, eventPost.name, eventPost.operational_event_type, eventPost.schedulability, eventPost.start_time, editorErrorMessage])

  const disabledSaveDraft = useMemo(() => {
    return (
      (eventPost.earliest_start_time && Datetime.isBefore(Datetime.toISOString(eventPost.start_time), Datetime.toISOString(eventPost.earliest_start_time)))
      || (Datetime.isBefore(Datetime.toISOString(eventPost.start_time), Datetime.toISOString('2000-01-01T00:00:00+00:00')))
      || (eventPost.latest_end_time && Datetime.isAfter(Datetime.toISOString(eventPost.end_time), Datetime.toISOString(eventPost.latest_end_time)))
      || (Datetime.isAfter(Datetime.toISOString(eventPost.end_time), Datetime.toISOString('2100-01-01T00:00:00+00:00')))
      || (Datetime.isAfter(Datetime.toISOString(eventPost.end_time), Datetime.toISOString('2100-01-01T00:00:00+00:00')))
      || (Datetime.isAfter(Datetime.toISOString(eventPost.start_time), Datetime.toISOString(eventPost.end_time)))
      || !!editorErrorMessage
    )
  }, [eventPost.earliest_start_time, eventPost.end_time, eventPost.latest_end_time, eventPost.start_time, editorErrorMessage])

  const createdAndUpdatedInfo = useMemo(() => {
    const createdInfo = eventPost.created_at
      ? `${t('Created by')}: ${eventPost.created_by ? eventPost.created_by : t('Unknown')} ${Datetime.toLocalTime(Datetime.toISOString(eventPost.created_at), 'longDay')}`
      : ``
    const updatedInfo = eventPost.updated_at ? `${t('Last updated')}: ${Datetime.toLocalTime(Datetime.toISOString(eventPost.updated_at), 'longDay')} ${t('by')} ${eventPost.updated_by ? eventPost.updated_by : t('Unknown')}` : ``

    if (eventPost.updated_at) {
      return `${createdInfo}, ${updatedInfo}`
    } else if (eventPost.created_by) {
      return createdInfo
    } else {
      return ''
    }
  }, [eventPost, t])

  const getNumberOfSettings = useCallback((index: number): number => {
    if (!settings || !eventPost?.linked_objects) return 0

    const settingsForProperty = settings?.filter(setting =>
      setting.name === eventPost?.linked_objects?.[index]?.object_name &&
          setting.attribute === eventPost?.linked_objects?.[index]?.attribute &&
          setting.level === eventPost?.linked_objects?.[index]?.object_category
    ) ?? []

    return settingsForProperty.length
  }, [settings, eventPost?.linked_objects])

  const hasAccessToUMMCheckbox = hasAccess({ submodule: 'operational_event_umm', module: 'events' })

  if (isFetching || !selectedEventPost) {
    return <></>
  }

  return (
    <>
      <Dialog open={true} fullWidth maxWidth="lg" onClose={(_, reason) => {
        if (reason === 'escapeKeyDown') {
          handleOnClose()
        }
      }}>
        <CustomDialogTitle
          title={eventPost.name ? eventPost.name : t('New event')}
          handleClose={() => handleOnClose()}
          info_text={!hasUpdateActiveOperationalEventsAccess && (statusActive || statusInactive) && (<InfoBanner text={t('Note that you do not have permission to change or update this event when the status is active or inactive')} style={'warningWithoutBackground'} />)}
        />
        <DialogContent>
          <div className={styles.EventPostModal_FormRow}>
            <TextField
              required
              fullWidth
              margin='dense'
              label={t('Name event')}
              disabled={!hasUpdateActiveOperationalEventsAccess && (statusActive || statusInactive)}
              value={eventPost.name ?? ''}
              onChange={(e) => {
                const newEventPost = {
                  ...eventPost,
                  name: e.target.value as string,
                }
                setEventPost(newEventPost)
                setIsEditing(checkIfEditing(newEventPost))
              }}
            />
            <Dropdown
              fullWidth
              margin='dense'
              label={t('Event type') + ' *'}
              disabled={!hasUpdateActiveOperationalEventsAccess && (statusActive || statusInactive)}
              items={EVENT_TYPES.map((type) => ({ value: type.value, label: t(type.label) }))}
              value={eventPost.operational_event_type ?? ''}
              handleChange={(e) => {
                const newEventPost = {
                  ...eventPost,
                  operational_event_type: e.target.value as EventPost['operational_event_type'],
                }
                setEventPost(newEventPost)
                setIsEditing(checkIfEditing(newEventPost))
              }}
            />
            <Dropdown
              fullWidth
              margin='dense'
              label={t('Cause category') + ' *'}
              disabled={!hasUpdateActiveOperationalEventsAccess && (statusActive || statusInactive)}
              items={eventCategories.map((type) => ({ value: type.id ?? '', label: t(type.display_name ?? '') }))}
              value={eventPost.category_id ?? ''}
              handleChange={(e) => {
                const newEventPost = {
                  ...eventPost,
                  category_id: e.target.value as number,
                }
                setEventPost(newEventPost)
                setIsEditing(checkIfEditing(newEventPost))
              }}
            />
          </div>
          <div className={styles.EventPostModal_FormRow}>
            <div className={styles.EventPostModal_DatePickerPeriod}>
              <Tooltip title={(!hasUpdateActiveOperationalEventsAccess && (statusActive || statusInactive)) ? t('You do not have permission to do update these fields') : ''} arrow>
                <div>
                  <DatePickerPeriod
                    isDisabled={!hasUpdateActiveOperationalEventsAccess && (statusActive || statusInactive)}
                    startDate={eventPost.start_time ? eventPost.start_time : Datetime.getISONow()}
                    endDate={eventPost.end_time ? eventPost.end_time : Datetime.getEndOfDay(Datetime.getISONow())}
                    onSelect={(startTime, endTime) => {
                      const newStartTime = startTime ? Datetime.toISOString(startTime) : Datetime.getStartOfDay(Datetime.getISONow())
                      const newEndTime = endTime ? Datetime.toISOString(endTime) : Datetime.getEndOfDay(Datetime.getISONow())
                      const newEventPost = {
                        ...eventPost,
                        start_time: newStartTime,
                        end_time: newEndTime,
                      }
                      setEventPost(newEventPost)
                      setIsEditing(checkIfEditing(newEventPost))
                    }}
                    minDate={(eventPost.earliest_start_time ? Datetime.toISOString(eventPost.earliest_start_time) : undefined) || Datetime.toISOString('2000-01-01T00:00:00+00:00')}
                    maxDate={(eventPost.latest_end_time ? Datetime.toISOString(eventPost.latest_end_time) : undefined) ||  Datetime.toISOString('2100-01-01T00:00:00+00:00')}
                  />
                </div>
              </Tooltip>
            </div>
            <div className={styles.EventPostModal_Schedulability}>
              <Dropdown
                fullWidth
                margin='dense'
                label={t('Schedulability') + ' *'}
                disabled={!hasUpdateActiveOperationalEventsAccess && (statusActive || statusInactive)}
                items={SCHEDULABILITIES.map((type) => ({ value: type.value, label: t(type.label) }))}
                value={eventPost.schedulability ?? ''}
                handleChange={(e) => {
                  const newEventPost = {
                    ...eventPost,
                    schedulability: e.target.value as EventPost['schedulability'],
                  }
                  setEventPost(newEventPost)
                  setIsEditing(checkIfEditing(newEventPost))
                }}
              />
            </div>
          </div>
          {(eventPost.schedulability === 'MoveableWithinLimits' || eventPost.schedulability === 'AlwaysMoveable') && (
            <>
              <div className={styles.EventPostModal_FormRow}>
                {eventPost.schedulability === 'MoveableWithinLimits' && (
                  <Tooltip title={(!hasUpdateActiveOperationalEventsAccess && (statusActive || statusInactive)) ? t('You do not have permission to do update these fields') : ''} arrow>
                    <div className={styles.EventPostModal_DatePickerPeriod_FormRow}>
                      <DatePickerPeriod
                        isDisabled={!hasUpdateActiveOperationalEventsAccess && (statusActive || statusInactive)}
                        startDate={eventPost.earliest_start_time ? eventPost.earliest_start_time : Datetime.toISOString(Datetime.subtractHours(eventPost.start_time, 1))}
                        endDate={eventPost.latest_end_time ? eventPost.latest_end_time : Datetime.toISOString(Datetime.addHours(eventPost.end_time, 1))}
                        onSelect={(startTime, endTime) => {
                          const newStartTime = startTime ? Datetime.toISOString(startTime) : Datetime.toISOString(Datetime.subtractHours(eventPost.start_time, 1))
                          const newEndTime = endTime ? Datetime.toISOString(endTime) : Datetime.toISOString(Datetime.addHours(eventPost.end_time, 1))
                          const newEventPost = {
                            ...eventPost,
                            earliest_start_time: newStartTime,
                            latest_end_time: newEndTime,
                          }
                          setEventPost(newEventPost)
                          setIsEditing(checkIfEditing(newEventPost))
                        }}
                        startLabel={t('Starts earliest')}
                        endLabel={t('Ends latest')}
                      />
                    </div>
                  </Tooltip>
                )}
                <div className={styles.EventPostModal_Consequence}>
                  <TextField
                    fullWidth
                    margin='dense'
                    label={t('Consequence of time adjustment')+ (schedulabilityEffectLength > 0 ? ` (${schedulabilityEffectLength}/${MAX_SCHEDULABILITY_EFFECT_INPUT_LENGTH})` : '')}
                    disabled={!hasUpdateActiveOperationalEventsAccess && (statusActive || statusInactive)}
                    value={eventPost.schedulability_effect}
                    inputProps={{ maxLength: MAX_SCHEDULABILITY_EFFECT_INPUT_LENGTH }}
                    onChange={(e) => {
                      setSchedulabilityEffectLength(e.target.value.length)
                      const newEventPost = {
                        ...eventPost,
                        schedulability_effect: e.target.value,
                      }
                      setEventPost(newEventPost)
                      setIsEditing(checkIfEditing(newEventPost))
                    }}
                  />
                </div>
              </div>
            </>
          )}
          {hasSettingsOutsideTimePeriod && (
            <div className={`${styles.EventPostModal_FormRow}`} style={{ marginBottom: '10px' }}>
              <InfoBanner
                text={t(`There are settings in this event outside the event time period`)}
                style={'warning'}
              />
            </div>
          )}
          <Tooltip title={!eventPost.operational_event_type ? t('Choose an event type to be able to edit') : ''} arrow placement='top'>
            <div className={styles.EventPostModal_FourthRow}>
              <ReactQuill
                ref={quillRef}
                modules={reactQuillModules}
                value={inputFieldEditorContent}
                readOnly={
                  (!hasUpdateActiveOperationalEventsAccess && (statusActive || statusInactive))
                || !eventPost.operational_event_type
                }
                className={styles.EventPostModal_QuillEditor}
                //delta and source, even though unused in the function, are required by Quills internal state management
                onChange={(content, delta, source, editor) => handleEditorChange(content, editor)} /> 
            </div>
          </Tooltip>
          {editorErrorMessage && <div className={styles.EventPostModal_EditorErrorMessage}>{t(editorErrorMessage)}</div>}
          <div className={styles.EventPostModal_FormRow}>
            {(hasAccessToUMMCheckbox) && (
              <FormGroup>
                <FormControlLabel
                  className={eventPost.require_umm ? styles.EventPostModal_CheckboxYellowBackground : styles.EventPostModal_Checkbox}
                  label={t('Requires UMM')}
                  control={<MuiCheckbox
                    checked={eventPost.require_umm}
                    onChange={() => {
                      const newEventPost = {
                        ...eventPost,
                        require_umm: !eventPost.require_umm,
                      }
                      setEventPost(newEventPost)
                      setIsEditing(checkIfEditing(newEventPost))
                    }}
                  />}
                />
              </FormGroup>)}


            <FormGroup>
              <FormControlLabel
                className={styles.EventPostModal_Checkbox}
                label={t('Cancelable after start')}
                control={<MuiCheckbox
                  checked={!!eventPost.cancelable_after_start}
                  onChange={() => {
                    const newEventPost = {
                      ...eventPost,
                      cancelable_after_start: !eventPost.cancelable_after_start,
                    }
                    setEventPost(newEventPost)
                    setIsEditing(checkIfEditing(newEventPost))
                  }}
                />}
              />
            </FormGroup>
          </div>
          <Divider className={styles.EventPostModal_Divider} />
          <Accordion
            disableGutters
            elevation={0}
            defaultExpanded={true}
            sx={{
              '&:before': { // Disable separator above accordion in collapsed mode
                display: 'none',
              },
            }}>
            <AccordionSummary expandIcon={<MuiIcon fontSize="small" className={`fas fa-chevron-down`} />}
              sx={{
                '&.MuiAccordionSummary-root': {
                  padding: 0,
                },
              }}>
              <span>{t('Affected components')}</span>
            </AccordionSummary>
            <AccordionDetails
              sx={{
                '&.MuiAccordionDetails-root': {
                  padding: '0',
                },
              }}
            >
              {hasSettingsOutsideTimePeriod && (
                <div className={styles.EventPostModal_FormRow} style={{ marginBottom: '15px' }}>
                  <InfoBanner inlineBlock text={t(`There are settings in this event outside the event time period`)} style={'warning'} />
                </div>
              )}

              {!eventPost.linked_objects
                ? <></>
                : eventPost.linked_objects?.map((property, index) => (
                  <div key={index}>
                    <div className={styles.EventPostModal_AlignCenter}>
                      <Tooltip title={getNumberOfSettings(index) > 0 ? t(`Affected component can't be changed when settings are connected. Remove settings to change these values or add a new affected component.`) : ''} arrow placement='top'>
                        <div className={styles.EventPostModal_FormRow}>
                          <EventPropertiesDropdowns
                            eventPost={eventPost}
                            setEventPost={setEventPost}
                            property={property}
                            checkIfEditing={checkIfEditing}
                            setIsEditing={setIsEditing}
                            error={disabledSameLinkedObjects}
                            index={index}
                            disabled={getNumberOfSettings(index) > 0 || (!hasUpdateActiveOperationalEventsAccess && (statusActive || statusInactive))}
                          />
                        </div>
                      </Tooltip>

                      {eventPost.linked_objects && (
                        <>
                          <div className={styles.EventPostModal_FormRow_ActionButtons}>
                            <Icon
                              icon="fad fa-times-circle"
                              color='red'
                              large
                              disabled={!hasUpdateActiveOperationalEventsAccess && (statusActive || statusInactive)}
                              onClick={() => removeEventPropertyField(index)}
                              tooltip={{ title: !hasUpdateActiveOperationalEventsAccess && (statusActive || statusInactive) ? t('You do not have permission to remove an affected component') : t('Remove affected component'),
                                placement: 'top',
                                arrow: true }}
                            />
                            <Icon
                              icon="fas fa-cog"
                              color={colors.grey6}
                              large
                              disabled={eventPost.id === null || !eventPost.linked_objects[index].attribute}
                              disabledTooltip={t('Select a property to add settings')}
                              onClick={() => toggleSettingOverview(index)}
                              tooltip={{ title: !hasUpdateActiveOperationalEventsAccess && (statusActive || statusInactive) ? t('View settings') : t('View/add settings'), placement: 'top', arrow: true }}
                            />
                            {getNumberOfSettings(index) > 0 && (
                              <Tooltip title={`${getNumberOfSettings(index)} ${t('Settings')}`} arrow placement='top'>
                                <div className={styles.EventPostModal_YellowCircle}/>
                              </Tooltip>
                            )}
                          </div>
                        </>
                      )}
                    </div>
                    {openSettingsOverview[index] && (
                      <EventSettingsOverviewTable
                        index={index}
                        eventPost={eventPost}
                        level={eventPost?.linked_objects?.[index]?.object_category ?? ''}
                        name={eventPost?.linked_objects?.[index]?.object_name ?? ''}
                        attribute={eventPost?.linked_objects?.[index]?.attribute ?? ''}
                        settings={settings}
                        refetchSettings={refetchSettings}
                      />
                    )}
                  </div>
                ))}

              {!hasUpdateActiveOperationalEventsAccess && (statusActive || statusInactive) ? <></> : (
                <div className={styles.EventPostModal_AddProperty}>
                  <Button
                    link
                    icon='fal fa-plus'
                    onClick={addEventPropertyField}
                  >
                    {t('Add')}
                  </Button>
                  {disabledSameLinkedObjects && (
                    <InfoBanner text={t(`You can't have two of the same affected components`)} style={'danger'} />
                  )}
                </div>
              )}
            </AccordionDetails>
          </Accordion>

          <Divider className={styles.EventPostModal_Divider} />
          <div className={styles.EventPostModal_EventInformation}>
            <p style={{fontStyle:'italic'}}>{createdAndUpdatedInfo}</p>
            <EventStatus status={status} />
          </div>

          {/* BUTTON COMBINATIONS:
            For more information about the different button and status combinations, see images provided in Pull Request: https://github.com/sigholm/aurora-react/pull/2833
          */}
          <div className={styles.EventPostModal_Buttons}>
            <div className={styles.EventPostModal_Buttons_Left}>
              {statusDraft ? (
                <Tooltip title={!hasDeleteOperationalEventsAccess ? t('You do not have permission to throw away this event') : ''} arrow>
                  <div>
                    <Button
                      disabled={!hasDeleteOperationalEventsAccess}
                      onClick={() => setConfirmOnThrowAwayModalOpen(true)}
                      className={hasDeleteOperationalEventsAccess ? styles.EventPostModal_Buttons_Left_ThrowAwayButton : undefined}
                    >
                      {t('Throw away')}
                    </Button>
                  </div>
                </Tooltip>
              ) : (
                <Tooltip title={!hasDeleteOperationalEventsAccess || (!hasDeleteActivatedOperationalEventsAccess && (statusActive || statusInactive)) ? t('You do not have permission to delete this event') : ''} arrow>
                  <div>
                    <Button
                      danger
                      disabled={!hasDeleteOperationalEventsAccess || (!hasDeleteActivatedOperationalEventsAccess && (statusActive || statusInactive))}
                      onClick={() => setConfirmDeleteModalOpen(true)}
                    >
                      {t('Delete')}
                    </Button>
                  </div>
                </Tooltip>
              )}
            </div>
            <div className={styles.EventPostModal_Buttons_Right}>
              {(statusDraft) && (
                <>
                  <Tooltip title={!hasUpdateOperationalEventsAccess ? t('You do not have permission to do update this event') : ''} arrow>
                    <div>
                      <Button
                        className={!hasUpdateOperationalEventsAccess || disabledSaveDraft ? styles.EventPostModal_Buttons__DisabledButton : styles.EventPostModal_Buttons__DraftButton}
                        disabled={!hasUpdateOperationalEventsAccess || disabledSaveDraft}
                        onClick={() => draftEvent(eventPost)}
                      >
                        {t('Save draft')}
                      </Button>
                    </div>
                  </Tooltip>
                  <Tooltip title={!hasUpdateOperationalEventsAccess || !hasDefaultStatusHandlingOperationalEventsAccess ? t('You do not have permission to submit this event') : disabledSubmit ? t('Please fill out all required * fields to continue') : ''} arrow>
                    <div>
                      <Button
                        primary
                        disabled={disabledSubmit || !hasUpdateOperationalEventsAccess || !hasDefaultStatusHandlingOperationalEventsAccess}
                        onClick={() => {
                          {
                            sendEvent(eventPost)
                          }}}
                      >
                        {t('Submit')}
                      </Button>
                    </div>
                  </Tooltip>
                </>
              )}
              {isEditing && !statusDraft ? (
                <Tooltip title={!hasUpdateOperationalEventsAccess || (!hasUpdateActiveOperationalEventsAccess && (statusActive || statusInactive)) ? t('You do not have permission to save this event') : disabledSubmit ? t('Please fill out all required * fields to continue') : ''} arrow>
                  <div>
                    <Button
                      primary
                      disabled={disabledSubmit || !hasUpdateOperationalEventsAccess || (saveSensitiveData && (!hasUpdateActiveOperationalEventsAccess && (statusActive || statusInactive)))}
                      onClick={handleOnSaveEdit}
                    >
                      {t('Save')}
                    </Button>
                  </div>
                </Tooltip>
              ) : (
                <>
                  {statusSubmitted && (
                    <>
                      <Tooltip title={!hasUpdateOperationalEventsAccess ? t('You do not have permission to do update this event') : ''} arrow>
                        <div>
                          <Button
                            className={!hasUpdateOperationalEventsAccess ? styles.EventPostModal_Buttons__DisabledButton : styles.EventPostModal_Buttons__DraftButton}
                            disabled={!hasUpdateOperationalEventsAccess}
                            onClick={() => draftEvent(eventPost)}
                          >
                            {t('Convert to draft')}
                          </Button>
                        </div>
                      </Tooltip>
                      <Tooltip title={!hasUpdateOperationalEventsAccess || !hasActiveStatusHandlingOperationalEventsAccess ? t('You do not have permission to do activate this event') : disabledSubmit ? t('Please fill out all required * fields to continue') : ''} arrow>
                        <div>
                          <Button
                            disabled={disabledSubmit || !hasUpdateOperationalEventsAccess || !hasActiveStatusHandlingOperationalEventsAccess}
                            className={disabledSubmit || !hasUpdateOperationalEventsAccess || !hasActiveStatusHandlingOperationalEventsAccess ? styles.EventPostModal_Buttons__DisabledButton : styles.EventPostModal_Buttons__ActiveButton}
                            onClick={() => activateEvent(eventPost)}
                          >
                            {t('Activate')}
                          </Button>
                        </div>
                      </Tooltip>
                    </>
                  )}
                  {statusActive && (
                    <Tooltip title={!hasUpdateOperationalEventsAccess || !hasActiveStatusHandlingOperationalEventsAccess ? t('You do not have permission to do inactivate this event') : disabledSubmit ? t('Please fill out all required * fields to continue') : ''} arrow>
                      <div>
                        <Button
                          disabled={disabledSubmit || !hasUpdateOperationalEventsAccess || !hasActiveStatusHandlingOperationalEventsAccess}
                          className={disabledSubmit || !hasUpdateOperationalEventsAccess || !hasActiveStatusHandlingOperationalEventsAccess ? styles.EventPostModal_Buttons__DisabledButton : styles.EventPostModal_Buttons__InactiveButton}
                          onClick={() => inactivateEvent(eventPost)}
                        >
                          {t('Inactivate')}
                        </Button>
                      </div>
                    </Tooltip>
                  )}
                  {statusInactive && (
                    <Tooltip title={!hasUpdateOperationalEventsAccess || !hasActiveStatusHandlingOperationalEventsAccess ? t('You do not have permission to do activate this event') : disabledSubmit ? t('Please fill out all required * fields to continue') : ''} arrow>
                      <div>
                        <Button
                          disabled={disabledSubmit || !hasUpdateOperationalEventsAccess || !hasActiveStatusHandlingOperationalEventsAccess}
                          className={disabledSubmit || !hasUpdateOperationalEventsAccess || !hasActiveStatusHandlingOperationalEventsAccess ? styles.EventPostModal_Buttons__DisabledButton : styles.EventPostModal_Buttons__ActiveButton}
                          onClick={() => activateEvent(eventPost)}
                        >
                          {t('Activate')}
                        </Button>
                      </div>
                    </Tooltip>
                  )}
                </>
              )}
            </div>
          </div>
        </DialogContent>
      </Dialog>

      <ConfirmDialog
        open={confirmDeleteModalOpen}
        onConfirm={() => {
          deleteEvent(eventPost?.id as number)
          closeModal()
          setConfirmDeleteModalOpen(false)
        }}
        onClose={() => setConfirmDeleteModalOpen(false)}
        textObject={{
          title: t(`Are you sure you want to remove this event?`),
          text: t(`This event and it's settings will be deleted and you will not be able to recover it.`),
        }}
      />
      <ConfirmDialog
        open={confirmOnCloseModalOpen}
        onConfirm={() => {
          resetEventPost()
          setConfirmOnCloseModalOpen(false)
          closeModal()
        }
        }
        onClose={() => setConfirmOnCloseModalOpen(false)}
        textObject={{
          title: t(`Are you sure you want to close this window?`) ,
          text: t(`All your progress will be lost if you continue.`),
          confirm: t('Close'),
        }}
        alternativeButton={
          statusDraft
            ? {
              text: 'Save draft',
              onClick: () => {
                draftEvent(eventPost)
                setConfirmOnCloseModalOpen(false)
                closeModal()
              },
            }
            : undefined
        }
      />
      <ConfirmDialog
        open={confirmOnThrowAwayModalOpen}
        onConfirm={() => {
          deleteEvent(eventPost.id as number)
          resetEventPost()
          closeModal()
          setConfirmOnThrowAwayModalOpen(false)
        }
        }
        onClose={() => setConfirmOnThrowAwayModalOpen(false)}
        textObject={{
          title: t(`Are you sure you want to throw away this event?`),
          text: t('All your progress will be lost if you continue.'),
          confirm: t('Throw away'),
        }}
        alternativeButton={
          {
            text: 'Save draft',
            onClick: () => {
              draftEvent(eventPost)
              setConfirmOnThrowAwayModalOpen(false)
              closeModal()
            },
          }
        }
      />
    </>
  )
}
