import React, { ReactElement, ReactNode } from 'react'

import { FormControl, InputLabel, MenuItem, Select, SelectChangeEvent } from '@mui/material'

type DropdownProps<ValueType> = {
  label: string
  items: { value: ValueType; label: ReactNode }[]
  value: ValueType
  handleChange: (event: SelectChangeEvent<ValueType>, child: ReactNode) => void
  fullWidth?: boolean
  disabled?: boolean
  margin?: 'none' | 'dense' | 'normal'
  style?: React.CSSProperties
  displayEmpty?: boolean
  inputProps?: Record<string, ReactNode>
  error?: boolean
}

export default function Dropdown<ValueType extends string | number | undefined>({
  label,
  items,
  value,
  handleChange,
  fullWidth,
  disabled = false,
  margin = 'none',
  style,
  displayEmpty,
  inputProps,
  error,
}: DropdownProps<ValueType>): ReactElement {
  const labelId = `dropdown-label-${label}`
  return (
    <FormControl variant="outlined" fullWidth={fullWidth} margin={margin} style={style}>
      <InputLabel id={labelId}>{label}</InputLabel>
      <Select
        fullWidth={fullWidth}
        labelId={labelId}
        id={`dropdown-select-${label}`}
        value={value}
        onChange={handleChange}
        label={label}
        displayEmpty={displayEmpty}
        disabled={disabled}
        inputProps={inputProps}
        error={error}
      >
        {items.map(({ value, label: itemLabel }, index) => (
          (<MenuItem key={`dropdown-${label}-${itemLabel}-${index}`} value={value}>
            {itemLabel}
          </MenuItem>)
        ))}
      </Select>
    </FormControl>
  )
}
